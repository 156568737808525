import {
    G3Card,
    G3Date,
    G3Gallery,
    G3Input,
    IconButton,
    InfoTag,
    ReactImageGalleryItem,
} from "@g3r-developers/g3-common";
import { TransportRequestDocsModel } from "@g3r-developers/vamp-types/dist/Transport/TransportRequestDocsModel";
import { ReviewRefurbModel } from "@g3r-developers/vamp-types/dist/vehicle/Refurb/ReviewRefurbModel";
import { ReviewRefurbUpdateModel } from "@g3r-developers/vamp-types/dist/vehicle/Refurb/ReviewRefurbUpdateModel";
import useVampToasts from "hooks/UseVampToasts";
import { RefurbStatus } from "lib/common/enums/RefurbStatus";
import { VehicleGrade } from "lib/common/enums/VehicleGrade";
import { VendorUpgradeResponsibility } from "lib/common/enums/attrib/VendorUpgradeResponsibility";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { formatMoney, isEmpty } from "services/utils";
import VehicleRefurbService from "services/vehicle/VehicleRefurbService";

interface RefurbReviewCardProps {
    onCompleted?: () => void;
    token: string;
    refurbId: number;
}

interface ReviewRefurbUpdateErrors {
    third_party_completion_date?: string;
    third_party_company_name?: string;
}

export default function RefurbReviewCard({ onCompleted, token, refurbId }: RefurbReviewCardProps) {
    const [refurb, setRefurb] = useState<ReviewRefurbModel>(undefined);
    const [refurbUpdate, setRefurbUpdate] = useState<ReviewRefurbUpdateModel>({} as ReviewRefurbUpdateModel);
    const [errors, setErrors] = useState<ReviewRefurbUpdateErrors>({});
    const [processingApprove, setProcessingApprove] = useState<boolean>(false);
    const [processingReject, setProcessingReject] = useState<boolean>(false);
    const [processingUpdate, setProcessingUpdate] = useState<boolean>(false);

    const { errorToast, successToast } = useVampToasts();

    const checkErrors = useCallback((data: ReviewRefurbUpdateModel) => {
        const e: ReviewRefurbUpdateErrors = {};

        if (!data.third_party_completion_date) {
            e.third_party_completion_date = "Please selected a date";
        }

        if (!data.third_party_company_name?.trim()) {
            e.third_party_company_name = "Please enter a company name";
        }

        setErrors(e);

        return !isEmpty(e);
    }, []);

    const loadReview = useCallback(() => {
        VehicleRefurbService.vendorReviewForRefurb(refurbId, { token: token })
            .then(r => {
                if (r.vehicle_refurb_status.id !== RefurbStatus.Suggested) {
                    onCompleted();
                    return;
                }
                setRefurb(r);
            })
            .catch(errorToast);
    }, [errorToast, onCompleted, refurbId, token]);

    const handleApprove = useCallback(() => {
        setProcessingApprove(true);
        VehicleRefurbService.vendorAcceptRefurbQuote(refurbId, { token: token } as TransportRequestDocsModel)
            .then(() => {
                loadReview();
                successToast("Refurb successfully approved");
                onCompleted();
            })
            .catch(errorToast)
            .finally(() => setProcessingApprove(false));
    }, [errorToast, loadReview, onCompleted, refurbId, successToast, token]);

    const handleReject = useCallback(() => {
        setProcessingReject(true);
        VehicleRefurbService.vendorRejectRefurbQuote(refurbId, { token: token } as TransportRequestDocsModel)
            .then(() => {
                loadReview();
                successToast("Refurb successfully rejected");
                onCompleted();
            })
            .catch(errorToast)
            .finally(() => setProcessingReject(false));
    }, [errorToast, loadReview, onCompleted, refurbId, successToast, token]);

    const handleSave = useCallback(() => {
        const hasErrors = checkErrors(refurbUpdate);

        if (hasErrors) {
            return;
        }

        setProcessingUpdate(true);

        VehicleRefurbService.vendorUpdateRefurbQuote(refurbId, {
            ...refurbUpdate,
            token: token,
        } as ReviewRefurbUpdateModel)
            .then(() => {
                loadReview();
                successToast("Refurb successfully saved");
                onCompleted();
            })
            .catch(errorToast)
            .finally(() => setProcessingUpdate(false));
    }, [checkErrors, errorToast, loadReview, onCompleted, refurbId, refurbUpdate, successToast, token]);

    const images = useMemo(() => {
        return refurb?.vehicle_refurb_media.map(_ => {
            return { original: _.url } as ReactImageGalleryItem;
        });
    }, [refurb?.vehicle_refurb_media]);

    const movementClass = useMemo(() => {
        return refurb?.offsite_requirement ? "bg-danger" : "bg-success";
    }, [refurb?.offsite_requirement]);

    const gradeClass = useMemo(() => {
        let classText = "w-100 ";
        switch (refurb?.vehicle_grade?.id) {
            case VehicleGrade.U: {
                return (classText += "bg-gradeU text-white");
            }
            case VehicleGrade._1: {
                return (classText += "bg-grade1 text-white");
            }
            case VehicleGrade._2: {
                return (classText += "bg-grade2");
            }
            case VehicleGrade._3: {
                return (classText += "bg-grade3");
            }
            case VehicleGrade._4: {
                return (classText += "bg-grade4");
            }
            case VehicleGrade._5: {
                return (classText += "bg-grade5 text-white");
            }
            default: {
                return (classText += "bg-gradeTBC text-white");
            }
        }
    }, [refurb?.vehicle_grade?.id]);

    useEffect(() => {
        loadReview();
    }, [loadReview]);

    return (
        <>
            {refurb && (
                <>
                    <Row className="d-flex justify-content-center">
                        <Col xs={12} className="mb-3 mt-3 text-center">
                            <span>
                                We have suggested the below refurbishment to be carried out on the below vehicle,
                                pending your approval.
                            </span>
                        </Col>
                        {refurb.inspection_url && (
                            <Col xs={12} className="mb-3 text-center">
                                <IconButton
                                    externalUrl={refurb.inspection_url}
                                    targetString="_blank"
                                    text="View Inspection"
                                />
                            </Col>
                        )}
                    </Row>
                    <G3Card>
                        <Row>
                            <h4 className="text-bold text-center">Vehicle Details</h4>
                            <Col xs={6}>
                                <InfoTag value={refurb?.vrm} className={"bg-warning w-100"} />
                            </Col>
                            <Col xs={6}>
                                <InfoTag
                                    title="Agreement Number"
                                    value={refurb?.agreement_num ? refurb?.agreement_num : "N/A"}
                                />
                            </Col>
                            <Col xs={6}>
                                <InfoTag title="Make/Model" value={`${refurb.make_name} ${refurb.model_name}`} />
                            </Col>
                            <Col xs={6}>
                                <InfoTag
                                    title="Current NAMA Grade"
                                    value={`${refurb.vehicle_grade ? refurb.vehicle_grade.title : "TBC"}`}
                                    className={gradeClass}
                                />
                            </Col>
                        </Row>
                    </G3Card>
                    <G3Card>
                        <Row>
                            <h4 className="text-bold text-center">Refurbishment Details</h4>

                            <Col xs={6}>
                                <InfoTag title="Refurb Type" value={`${refurb.type?.title}`} />
                            </Col>
                            {refurb.responsibility.id === VendorUpgradeResponsibility.G3 && (
                                <Col xs={6}>
                                    <InfoTag
                                        title="Quoted Cost"
                                        value={`${formatMoney(
                                            refurb.vehicle_job?.consignment?.current_quote?.amount_to_vendor,
                                            false
                                        )}`}
                                    />
                                </Col>
                            )}
                            <Col xs={6}>
                                <InfoTag
                                    title="Movement Required"
                                    value={refurb.offsite_requirement ? "Yes" : "No"}
                                    className={`w-100 text-white ${movementClass}`}
                                />
                            </Col>
                            {refurb.potential_grade && (
                                <Col xs={6}>
                                    <InfoTag
                                        title="NAMA Grade (once refurb completed)"
                                        value={refurb.potential_grade?.title}
                                    />
                                </Col>
                            )}
                            {refurb.responsibility.id === VendorUpgradeResponsibility.ThirdParty && (
                                <>
                                    <Col xs={6}>
                                        <G3Date
                                            label="When are works due to be completed?"
                                            min="today"
                                            selectedDate={refurbUpdate?.third_party_completion_date}
                                            onChange={(e: string) =>
                                                setRefurbUpdate(p => ({ ...p, third_party_completion_date: e }))
                                            }
                                            error={errors.third_party_completion_date}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <G3Input
                                            label="Who will carry out the works?"
                                            type="text"
                                            value={refurbUpdate?.third_party_company_name}
                                            onChange={(e: string) =>
                                                setRefurbUpdate(p => ({ ...p, third_party_company_name: e }))
                                            }
                                            error={errors.third_party_company_name}
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </G3Card>
                    <Row className="d-flex justify-content-center">
                        {refurb?.vehicle_refurb_media?.length > 0 && (
                            <>
                                <h4 className="text-bold text-center">Evidence</h4>
                                <Col xs={"auto"}>
                                    <G3Gallery items={images} />
                                </Col>
                            </>
                        )}
                        <Col xs={12}>
                            <Row className="my-4">
                                {refurb.responsibility.id === VendorUpgradeResponsibility.G3 ? (
                                    <>
                                        <Col xs={6}>
                                            <IconButton
                                                block
                                                className="bg-danger text-white w-100"
                                                disabled={processingApprove || processingReject}
                                                faIconString={processingApprove && "fas fa-spinner fa-spin"}
                                                onClick={handleReject}
                                                text="Reject"
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <IconButton
                                                block
                                                className="bg-success text-white w-100"
                                                disabled={processingApprove || processingReject}
                                                faIconString={processingReject && "fas fa-spinner fa-spin"}
                                                onClick={handleApprove}
                                                text="Approve"
                                            />
                                        </Col>
                                    </>
                                ) : (
                                    <Col>
                                        <IconButton
                                            block
                                            disabled={processingUpdate}
                                            faIconString={processingUpdate && "fas fa-spinner fa-spin"}
                                            onClick={handleSave}
                                            text="Save"
                                            variant="secondary"
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
}
