import * as Sentry from "@sentry/react";
import { RootState } from "index";
import { useSelector } from "react-redux";

const useValidateSession = (): boolean => {
    const user = useSelector((state: RootState) => state.session.user);

    if (!user) {
        return false;
    }

    if (!user?.expiry) {
        return false;
    }

    const expiryDate = new Date(user.expiry);

    if (expiryDate < new Date()) {
        Sentry.setUser(null);
        return false;
    }

    Sentry.setUser({
        id: user.contact.contact_id.toString(),
        email: user.contact.email,
        username: user.contact.full_name,
    });

    window.Trengo.contact_data = {
        email: user.contact.email,
        name: user.contact.full_name,
    };

    const win = window as any;

    if (typeof win.Featurebase === "function") {
        win.Featurebase("initialize_portal_widget", {
            organization: "vamp", // required
            placement: "left", // optional
            fullScreen: false, // optional
            jwtToken: user.sso_jwt,
        });

        win.Featurebase("initialize_changelog_widget", {
            organization: "vamp", // Replace this with your featurebase organization name
            placement: "right", // Choose between right, left, top, bottom placement (Optional if fullscreenPopup is enabled)
            theme: "light", // Choose between dark or light theme
            fullscreenPopup: true, // Optional - Open a fullscreen announcement of the new feature to the user
            usersName: user.contact.forename, // Optional - Show the users name in the welcome message for the fullscreen popup
        });
    }

    return true;
};

export { useValidateSession };
